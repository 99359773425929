@import "~core/components/formElements";

.form-control-description {
    margin-bottom: .25rem;
    color: $color-grey-5;

    &.required {
        &:after {
            content: "*";
        }
    }
}

[class*="form-check-label"] {
    display: inline-block;
}

::-ms-reveal {
    display: none;
}
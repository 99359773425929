@import "~core/components/footer/footerMain";

.footer-partners {
    border-top: solid 0.0625rem $color-grey-3;
    border-bottom: solid 0.0625rem $color-grey-3;
    margin-bottom: 2.5rem;
}

@include media-query(small-up) {
    .footer-partners {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }

    .footer-partners__img {
        height: 2rem;
    }
}

.footer--businessdetails {
    padding: 1.05263rem;
}

.footer-business-information {
    font-size: 0.719rem;
}

.footer--businessdetails_escrowlink {
    margin-top: 0.9375rem;
    margin-bottom: 0.625rem;
}

.footer--businessdetails__text {
    border-right: 1px solid $color-grey-6;
    padding: 0 7px;

    &:last-of-type {
        border-right: 0;
    }
}

// footer checkout
.footer__main-content--checkout--wrapper {
    justify-content: space-between;
}

.footer-content-col {
    height: 100%;
    border-right: 1px solid #bdbdbd;
    text-align: center;

    &:last-of-type {
        border-right: 0;
    }

    @include media-query(small) {
        border-right: 0;
        border-bottom: 1px solid #bdbdbd;
        margin-bottom: 0;
        padding-bottom: 3.5rem;

        &:last-of-type {
            border-right: 0;
            border-bottom: 0;
        }
    }
}

.footer-content__heading {
    margin-top: 1.25rem;
}

.footer-content__links {
    justify-content: center;
}

.footer-content__description img {
    margin: 0 auto;
}

.modal--generic {
    .cms-page--single,
    .cms-page--group {
        padding: 2rem;
    }
}

.footer-content-col {
    flex-basis: 33.33%;
}

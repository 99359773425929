@import "~app_accelerator_core/modules/modals";
@import "~app_rna_core/modules/modals";

.modal__body {
    padding-bottom: 2.5rem;
}

.modal-form--item {
    border: 1px solid $color-black;
    margin-top: .625rem;
    margin-bottom: 1.25rem;
    padding: .625rem;

    .form-check-group__item {
        margin-top: .625rem;
        margin-bottom: 0;
    }

    .form-check-group {
        margin-top: .625rem;
    }

    .feedback--large-spacing-top {
        margin-top: 1.5rem;
    }
}

.modal-form--item-consent {
    margin-top: .1rem;
    margin-bottom: 0;
    padding: .1rem;

    .form-check-group__item {
        margin-top: .625rem;
        margin-bottom: 0;
    }

    .form-check-group {
        margin-top: .625rem;
    }

    .feedback--large-spacing-top {
        margin-top: 1.5rem;
    }
}
.model--consent {
    border-bottom: 1px solid $color-black;
}
.modal--privacy-popup {
    .window-modal__close {
        top: 0.6rem;
    }

    .window-modal__content {
        padding: 3rem 1rem 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.modal--account-reactivation-landingcontent,
.modal--delete-profile,
.modal--return-exchange-order {
    .modal__header {
        &.set--no-padding {
            padding: 0;
        }
        &.set--no-margin {
            margin: 0;
        }
    }
    .modal__accept-cta {
        margin-bottom: 1rem;
    }
}

.modal--consent {
    margin-top: 2rem;

    .modal-consent__description {

        margin-bottom: 1rem;
        padding-bottom: 0;

        .toggle--active {
            .modal-content__details {
                display: block;
            }

            .modal-read-more {
                display: none;
            }

            .modal-read-less {
                display: inline-block;
                margin-bottom: .625rem;
            }

        }

        &:not(.toggle--active) {
            .modal-content__details {
                display: none;
            }
        }

            .modal-read-less {
                display: none;
            }
        }
    }

@include media-query(small-up) {
    .window-modal {
        padding: 2rem;
        &.modal--product-zoom, &.modal--sizeGuide {
            padding: 0;
        }
    }
}

.modal--delete-profile {
    .heading-type {
        margin-bottom: 1.25rem;
    }
    .login-form {
        margin-top: 1.875rem;
    }
}
